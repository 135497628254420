/* eslint-disable no-restricted-syntax */
/* eslint-disable no-nested-ternary */
/* eslint-disable import/no-extraneous-dependencies */
// import parse from 'html-react-parser'

const cheerio = typeof window === 'undefined' ? global.cheerio : window.cheerio

export function getInnerText(content) {
  const $ = cheerio.load(content?.metaDescription || '')
  const textContent = $('p').text()
  return textContent || content?.metaDescription
}

export function extractImageTagfromContentBeforeTocArrayFormat(contentArray) {
  if (contentArray) {
    // Iterate over the content array to find the first image
    for (const content of contentArray) {
      if (content.__component === 'text-editor.rich-text-ck') {
        const $ = cheerio.load(content.Content)
        const altPattern = /alt\s*=\s*["']?([^"'\n]+)["']?/i
        const altTextMatch = content.Content.match(altPattern)
        const img = $('img').first()

        if (img.length > 0) {
          return {
            url: img.attr('src') || '',
            alt: altTextMatch?.[1] || img.attr('alt') || '',
            width: img.attr('width') || '741',
            height: img.attr('height') || '389',
          }
        }
      }
    }
    return null // No <img> tag found in any of the content blocks
  }
}

export const baseUrl =
  typeof window !== 'undefined'
    ? `${window.location.protocol}//${window.location.host}`
    : process.env.ACKO_ENV === 'master'
      ? 'https://carbon.ackodev.com/'
      : process.env.ACKO_ENV === 'prod'
        ? 'https://www.acko.com'
        : 'http://localhost:3000/product/'

// Function to find and extract the content of the "description" tag
function findDescriptionTagContent(tagsArray) {
  if (tagsArray) {
    for (const tag of tagsArray) {
      if (tag.includes('name="description"')) {
        const startContentIndex = tag.indexOf('content="') + 'content="'.length
        const endContentIndex = tag.indexOf('"', startContentIndex)
        return tag.substring(startContentIndex, endContentIndex)
      }
    }

    return null
  }
  return null
}

export const productSnippetFunc = (content, parsedMetaDescription) => ({
  name: content?.metaTitle,
  description:
    parsedMetaDescription ||
    findDescriptionTagContent(content?.seoTags?.split('/>')) ||
    'This product is policy provided by ACKO General Insurance. It protects you against financial liability arising from incidents like accidental damages and theft',
  image: `https://${content?.productWidget?.data?.attributes?.featuredImage?.data?.attributes?.url}`, // Hero image needs to change
  url: `${baseUrl}${content?.url}`,
})

export function getReactInnerText(obj) {
  let buf = ''
  if (obj) {
    const type = typeof obj
    if (type === 'string' || type === 'number') {
      buf += obj
    } else if (type === 'object') {
      let children: any = null
      if (Array.isArray(obj)) {
        children = obj
      } else {
        const props = obj.props
        if (props) {
          children = props.children
        }
      }
      if (children) {
        if (Array.isArray(children)) {
          children.forEach(o => {
            buf += getReactInnerText(o)
          })
        } else {
          buf += getReactInnerText(children)
        }
      }
    }
  }
  return buf
}

export const decodeHTML = htmlString => {
  const parser = new DOMParser()
  // parse as 'text/html' so &lt;script&gt; => <script>
  const doc = parser.parseFromString(htmlString, 'text/html')
  // The DOMParser will create a document, and its .body.textContent is the unescaped string
  return doc?.body?.textContent || ''
}

// 2) Clean up curly quotes, non-breaking spaces, etc., inside the returned string.
export const cleanJSONLD = jsonLD => {
  if (!jsonLD) return ''
  return (
    jsonLD
      // Replace curly (smart) double-quotes with ASCII quotes
      .replace(/[\u201C\u201D\u201E\u201F]/g, '"')
      // Replace curly (smart) single-quotes with ASCII single quotes
      .replace(/[\u2018\u2019\u201A\u201B]/g, "'")
      // Replace non-breaking spaces with normal spaces
      .replace(/\u00A0/g, ' ')
  )
}
